import React from "react"
import {Formik, Field, Form} from "formik"
import {navigate} from "gatsby-link"
import validationSchema from "./validationSchema"

const encode = (data) => {
  console.log(data);
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&")
}

const ContactForm = () => {
    return (
        <Formik
            initialValues={{name: "", email: "", message: "", services: [] }}
            validationSchema={validationSchema}
            onSubmit={(values, {setSubmitting, resetForm}) => {

                fetch("https://api.slatedata.co.uk/form-submit", {                                 //eslint-disable-line
                    method: "POST",
                    mode: "no-cors",
                    headers: {"Content-Type": "application/json", "Access-Control-Allow-Origin": "https://api.slatedata.co.uk"},
                    body: JSON.stringify({
                        "form-name": "contact",
                        ...values,
                    }),
                })
                    .then(() => {
                        // navigate("/contact/success")
                        alert("Thanks, we'll be in touch soon!");
                        setSubmitting(false);
                        resetForm({});
                    })
                    .catch(error => {
                        console.log(error)
                        alert("Error: Please Try Again!");                            //eslint-disable-line
                        setSubmitting(false)
                    })
            }}
            // >
            render=
            {({
                         errors,
                         touched,
                         isSubmitting,
                         handleSubmit,
                         handleReset,
                     }) => (
            <Form name="contact" onSubmit={handleSubmit} onReset={handleReset}>
                <div className="columns is-multiline">
                    <div className="field column is-6">
                        <label className="label">Name</label>
                        <div className="control">
                            <Field className="input" type="text" placeholder="Full Name" name="name" id="name"/>
                        </div>
                        {touched.name && errors.name && <small className="has-text-danger">{errors.name}</small>}
                    </div>

                    <div className="field column is-6">
                        <label className="label">Email</label>
                        <div className="control">
                            <Field className="input" type="email" placeholder="Email" name="email" id="email"/>
                        </div>
                        {touched.email && errors.email && <small className="has-text-danger">{errors.email}</small>}
                    </div>
                    <div id="services-group">Which Services are you interested in?</div>
                    <div id="services" role="group" className="columns is-multiline" arialabelled-by="services-group">
                        <label className="column is-6 checkbox">
                            <Field type="checkbox" name="services" value="migration"/>
                            Cloud Migration
                        </label>
                        <label className="column is-6 checkbox">
                            <Field type="checkbox" name="services" value="refactoring"/>
                            Service Refactoring
                        </label>
                        <label className="column is-6 checkbox">
                            <Field type="checkbox" name="services" value="etl"/>
                            ETL Pipelines
                        </label>
                        <label className="column is-6 checkbox">
                            <Field type="checkbox" name="services" value="architecture"/>
                            Data Architecture
                        </label>
                        <label className="column is-6 checkbox">
                            <Field type="checkbox" name="services" value="iam"/>
                            IAM & Security
                        </label>
                        <label className="column is-6 checkbox">
                            <Field type="checkbox" name="services" value="advice"/>
                            Data & Advisory Services
                        </label>
                        <label className="column is-6 checkbox">
                            <Field type="checkbox" name="services" value="automation"/>
                            Data & Process Automation
                        </label>
                        <label className="column is-6 checkbox">
                            <Field type="checkbox" name="services" value="warehouse"/>
                            Data Warehousing And Visualisation
                        </label>
                        <label className="column is-6 checkbox">
                            <Field type="checkbox" name="services" value="other"/>
                            Other - Let us know in your message
                        </label>
                        {touched.services && errors.services && <small className="has-text-danger">{errors.services}</small>}
                    </div>
                    <div className="field column is-12">
                        <label className="label">Message</label>
                        <div className="control">
                            <Field className="textarea" component="textarea" placeholder="Please tell us a bit more about your project and what your requirements are." name="message"
                                   id="message" rows="3"/>
                        </div>
                        {touched.message && errors.message &&
                        <small className="has-text-danger">{errors.message}</small>}
                    </div>
                    <div className="column is-12">
                        <div className="field is-grouped is-pulled-right">
                            <div className="control">
                                <button className="button" type="reset">Clear</button>
                            </div>
                            <div className="control">
                                <button className="button is-primary" type="submit" action="onSubmit" disabled={isSubmitting}>Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>)}
        />
    )
}

export default ContactForm
